/* eslint-disable max-len */
import AnimationHealthcare from '../assets/videos/healthcare.mp4';
import AnimationFinance from '../assets/videos/finance.mp4';

const data = {
  city: {
    imageUrl: 'city.png',
    clickPoints: [
      {
        id: 1,
        name: 'Finance',
        slug: 'finance',
        pinLocation: {
          x: 11,
          y: 30
        },
        position: 'BOTTOM',
        height: '260',
        description: 'description'
      },
      {
        id: 2,
        name: 'High Tech',
        slug: 'office',
        pinLocation: {
          x: 31,
          y: 26
        },
        position: 'BOTTOM',
        height: '260',
        description: 'description'
      },
      {
        id: 3,
        slug: 'healthcare',
        name: 'Healthcare',
        pinLocation: {
          x: 51,
          y: 74
        },
        position: 'BOTTOM',
        height: '260',
        description: 'description'
      },
      {
        id: 4,
        slug: 'government',
        name: 'Government',
        pinLocation: {
          x: 36,
          y: 70
        },
        position: 'BOTTOM',
        height: '260',
        description: 'description'
      }
    ]
  },
  finance: {
    imageUrl: 'finance.jpg',
    list: [
      {
        id: 1,
        name: 'Identify Separation of Duties (SOD) Conflicts',
        description:
          'Learn how Access Risk Management allows you to rapidly identify SOD and sensitive access risks across your SAP systems.',
        outro:
          'Discover on-going risks by user, role, and business process — with actionable, business-ready intelligence.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=93fe08a4-196c-404e-b032-45767d68dc03&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Finance'],
        pinLocation: {
          x: 68,
          y: 28
        },
        drillDown: {
          animation: AnimationFinance
        }
      },
      {
        id: 2,
        name: 'Streamline role creation',
        description:
          'Building and maintaining access roles is key to maintaining a productive and secure workforce in a least privilege environment.',
        outro: '', // No outro provided
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=3bb8b3f2-d210-4a61-bbb8-22e074964ace&showGuide=true&showGuidesToolbar=false&showHotspots=true&isEmbed=true'
          }
        ],
        categories: ['Finance'],
        pinLocation: {
          x: 38,
          y: 58
        }
      },
      {
        id: 3,
        name: 'New employee access request',
        description:
          'See how the IdentityNow Request Center streamlines the process of requesting access for yourself, your team, or anyone in your organization.',
        outro: 'Requesting access to resources should be easy for your end-users for them and your helpdesk.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=ac6ceed1-0a5e-4119-981d-88e78fe7d077&screenId=40ad39f8-69a7-4631-aa0c-d3781faf6c9e&showGuide=true&showGuidesToolbar=false&showHotspots=true&isEmbed=false'
          }
        ],
        categories: ['Finance'],
        pinLocation: {
          x: 39,
          y: 33
        }
      },
      {
        id: 4,
        name: 'Work Reassignment',
        description: 'Learn how to reassign tasks automatically.',
        outro: 'Keep your SailPoint IdentityNow solution operating smoothly by automatically reassigning tasks.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=46378b86-ddc4-4849-9bae-62e237b360f1&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Finance'],
        pinLocation: {
          x: 78,
          y: 10
        }
      },
      {
        id: 5,
        name: 'Adjusting access as needed',
        description:
          'Maintaining appropriate access across your organization is an everyday occurrence. With people joining the company, changing roles and leaving keeping access up to date in a timely manner is critical.',
        outro:
          'Adjusting access as your organization changes is critical to maintaining a least privilege approach to access. Having a system that makes it automatic helps you focus on other key items.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=c2db6399-e3b2-41c3-b0e5-9061b7853c98&showGuide=true&showGuidesToolbar=false&showHotspots=true&isEmbed=true'
          }
        ],
        categories: ['Finance'],
        pinLocation: {
          x: 49,
          y: 6
        }
      },
      {
        id: 6,
        name: 'Managing third-party non-employee identities',
        description: 'See how Non-Employee Risk Management helps you manage third-party non-employee identities.',
        outro: 'Extend the value of your identity security program with Non-Employee Risk Management!',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=1bf1412b-9cba-4e3c-8e56-fb47db6bd57d&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Finance'],
        pinLocation: {
          x: 64,
          y: 50
        }
      },
      {
        id: 7,
        name: 'Automate workflows for outliers',
        description:
          'Discover how to use Workflows to automate your identity security program and match the speed of modern business.',
        outro:
          'Based on the customized needs of your organization, multiple workflows can be created to automatically respond to different outlier scores.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=403dce53-cc87-4839-8e06-4ec7eab075c1&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Finance'],
        pinLocation: {
          x: 85,
          y: 34
        }
      },
      {
        id: 8,
        name: 'Remove access quickly',
        description: 'Quickly removing access is critical for organizations that want to maintain least privilege.',
        outro:
          'Organizations that do not have an automated process to remove access for former employees open themselves up to potential security risks that could easily been avoided. By integrating a strong identity security system with your HR system of record, automatically removing access when an employee leaves your organization is key to keeping access secure.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=4d007279-fe4e-4e1c-a293-4a7aac755948&showGuide=true&showGuidesToolbar=false&showHotspots=true&isEmbed=true'
          }
        ],
        categories: ['Finance'],
        pinLocation: {
          x: 29,
          y: 72
        }
      },
      {
        id: 9,
        name: 'Govern identity security with MySailPoint',
        description:
          'See how MySailPoint’s widget-based dashboard acts as the access governance command center to provide you insights across all your SailPoint solutions.',
        outro: '',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=32245c91-e75d-4930-931d-4b48f09d894c&sh[…]ue&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Finance'],
        pinLocation: {
          x: 12,
          y: 32
        }
      },
      {
        id: 10,
        name: 'Discover and manage access for unstructured data',
        description:
          'See how Data Access Security provides integrated insights on sensitive data to help organizations minimize the risks of unstructured data through intelligent automation.',
        outro: '',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=f73a10f7-7fd5-4ea3-8c63-4e30a403916d&sh[…]e&showGuidesToolbar=false&showHotspots=true&source=app'
          }
        ],
        categories: ['Finance'],
        pinLocation: {
          x: 77,
          y: 71
        }
      }
    ]
  },
  healthcare: {
    imageUrl: 'healthcare.jpg',
    list: [
      {
        id: 1,
        name: 'Healthcare New Employee Access Request',
        description:
          'See how the Request Center streamlines the process of requesting access for yourself, your team, or anyone in your healthcare organization.',
        outro: 'Accelerate secure healthcare access requests',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=01b4214b-66ea-4321-a857-ff4a4ef39a16&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Healthcare'],
        pinLocation: {
          x: 82,
          y: 35
        },
        drillDown: {
          animation: AnimationHealthcare
        }
      },
      {
        id: 2,
        name: 'Managing third-party healthcare non-employee identities',
        description:
          'See how Non-Employee Risk Management helps you manage third-party non-employee identities in healthcare.',
        outro: 'TBC',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=af0349d1-7ac1-4e1e-a11b-e767d85d2e6e&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Healthcare'],
        pinLocation: {
          x: 50,
          y: 3
        }
      },
      {
        id: 3,
        name: 'Adjusting access as needed',
        description:
          'Maintaining appropriate access across your organization is an everyday occurrence. With people joining the company, changing roles and leaving, keeping access up to date in a timely manner is critical.',
        outro:
          'Adjusting access as your organization changes is critical to maintaining a least privilege approach to access. Having a system that makes it automatic helps you focus on other key items.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=c2db6399-e3b2-41c3-b0e5-9061b7853c98&showGuide=true&showGuidesToolbar=false&showHotspots=true&isEmbed=true'
          }
        ],
        categories: ['Healthcare'],
        pinLocation: {
          x: 49,
          y: 30
        }
      },
      {
        id: 4,
        name: 'Automate workflows for outliers',
        description:
          'Discover how to use Workflows to automate your identity security program and match the speed of modern business.',
        outro:
          'Based on the customized needs of your organization, multiple workflows can be created to automatically respond to different outlier scores.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=403dce53-cc87-4839-8e06-4ec7eab075c1&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Healthcare'],
        pinLocation: {
          x: 30,
          y: 15
        }
      },
      {
        id: 5,
        name: 'Streamline role creation',
        description:
          'Building and maintaining access roles is key to maintaining a productive and secure workforce in a least privilege environment.',
        outro: '', // It seems there's no outro text for this one
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=3bb8b3f2-d210-4a61-bbb8-22e074964ace&showGuide=true&showGuidesToolbar=false&showHotspots=true&isEmbed=true'
          }
        ],
        categories: ['Healthcare'],
        pinLocation: {
          x: 29,
          y: 35
        }
      },
      {
        id: 6,
        name: 'Review access for uncorrelated accounts',
        description:
          'Gain a comprehensive view of all identities and access including accounts that from non-authoritative systems that are not mapped to an identity.',
        outro: 'Simplify achieving compliance mandates and automate the certification of uncorrelated accounts.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=bf2cff42-80aa-4ca5-971f-0f28c5e01329&showGuide=true&showGuidesToolbar=false&showHotspots=true&embedInDomain=&isEmbed=true'
          }
        ],
        categories: ['Healthcare'],
        pinLocation: {
          x: 61,
          y: 76
        }
      },
      {
        id: 7,
        name: 'Remove access quickly',
        description:
          'Quickly removing access is critical for organizations that want to maintain least privilege. Organizations that do not have an automated process to remove access for former employees open themselves up to potential security risks that could easily have been avoided.',
        outro:
          'By integrating a strong identity security system with your HR system of record, automatically removing access when an employee leaves your organization is key to keeping access secure.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=4d007279-fe4e-4e1c-a293-4a7aac755948&showGuide=true&showGuidesToolbar=false&showHotspots=true&isEmbed=true'
          }
        ],
        categories: ['Healthcare'],
        pinLocation: {
          x: 56,
          y: 49
        }
      },
      {
        id: 8,
        name: 'Make smarter access decisions',
        description:
          'Access Recommendations help business managers and application owners make informed decisions, reduce certification fatigue, and improved productivity.',
        outro:
          'Automate low-risk access review and prioritize focus on high-risk user access that avoids rubber stamping which leads to incorrect access approvals.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=6901be05-4bfb-4a21-ac6b-7d0d3f69c282&showGuide=true&showGuidesToolbar=false&showHotspots=true&isEmbed=true'
          }
        ],
        categories: ['Healthcare'],
        pinLocation: {
          x: 68,
          y: 13
        }
      },
      {
        id: 9,
        name: 'Govern identity security with MySailPoint',
        description:
          'See how MySailPoint’s widget-based dashboard acts as the access governance command center to provide you insights across all your SailPoint solutions.',
        outro: '',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=32245c91-e75d-4930-931d-4b48f09d894c&sh[…]ue&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Healthcare'],
        pinLocation: {
          x: 32,
          y: 68
        }
      },
      {
        id: 10,
        name: 'Discover and manage access for unstructured data',
        description:
          'See how Data Access Security provides integrated insights on sensitive data to help organizations minimize the risks of unstructured data through intelligent automation.',
        outro: '',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=f73a10f7-7fd5-4ea3-8c63-4e30a403916d&sh[…]e&showGuidesToolbar=false&showHotspots=true&source=app'
          }
        ],
        categories: ['Healthcare'],
        pinLocation: {
          x: 80,
          y: 64
        }
      }
    ]
  },
  office: {
    imageUrl: 'office.jpg',
    list: [
      {
        id: 1,
        name: 'Adjusting access as needed',
        pinLocation: {
          x: 73,
          y: 8
        },
        position: 'BOTTOM',
        height: '260',
        description:
          'Maintaining appropriate access across your organization is an everyday occurrence. With people joining the company, changing roles and leaving keeping access up to date in a timely manner is critical.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=c2db6399-e3b2-41c3-b0e5-9061b7853c98&showGuide=true&showGuidesToolbar=false&showHotspots=true&isEmbed=true'
          }
        ],
        categories: ['High Tech']
      },
      {
        id: 2,
        name: 'Automate workflows for outliers',
        pinLocation: {
          x: 24,
          y: 37
        },
        position: 'BOTTOM',
        height: '260',
        description:
          'Discover how to use Workflows to automate your identity security program and match the speed of modern business.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=403dce53-cc87-4839-8e06-4ec7eab075c1&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['High Tech']
      },
      {
        id: 3,
        name: 'Govern cloud workloads',
        pinLocation: {
          x: 12,
          y: 56
        },
        position: 'BOTTOM',
        height: '260',
        description:
          'Increased cloud infrastructure adoption has underscored the need to closely govern and manage access across multiple cloud service providers, including Microsoft Azure, Amazon Web Services and Google Cloud Platform.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=64ee2ae6-148f-4178-b022-9d4546ab61c0&showGuide=true&showGuidesToolbar=false&showHotspots=true&isEmbed=true'
          }
        ],
        categories: ['High Tech']
      },
      {
        id: 4,
        name: 'Make smarter access decisions',
        pinLocation: {
          x: 58,
          y: 22
        },
        position: 'BOTTOM',
        height: '260',
        description:
          'Access Recommendations help business managers and application owners make informed decisions, reduce certification fatigue, and improved productivity.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=6901be05-4bfb-4a21-ac6b-7d0d3f69c282&showGuide=true&showGuidesToolbar=false&showHotspots=true&isEmbed=true'
          }
        ],
        categories: ['High Tech']
      },
      {
        id: 5,
        name: 'New employee access request',
        pinLocation: {
          x: 32,
          y: 51
        },
        position: 'BOTTOM',
        height: '210',
        description:
          'See how the IdentityNow Request Center streamlines the process of requesting access for yourself, your team, or anyone in your organization.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=ac6ceed1-0a5e-4119-981d-88e78fe7d077&screenId=40ad39f8-69a7-4631-aa0c-d3781faf6c9e&showGuide=true&showGuidesToolbar=false&showHotspots=true&isEmbed=false'
          }
        ],
        categories: ['High Tech']
      },
      {
        id: 6,
        name: 'Remove access quickly',

        pinLocation: {
          x: 45,
          y: 32
        },
        position: 'BOTTOM',
        height: '230',
        description:
          'Organizations that do not have an automated process to remove access for former employees open themselves up to potential security risks that could easily been avoided.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=4d007279-fe4e-4e1c-a293-4a7aac755948&showGuide=true&showGuidesToolbar=false&showHotspots=true&isEmbed=true'
          }
        ],
        categories: ['High Tech']
      },
      {
        id: 7,
        name: 'Review access for uncorrelated accounts',
        pinLocation: {
          x: 32,
          y: 20
        },
        position: 'BOTTOM',
        height: '260',
        description:
          'Gain a comprehensive view of all identities and access including accounts that from non-authoritative systems that are not mapped to an identity.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=bf2cff42-80aa-4ca5-971f-0f28c5e01329&showGuide=true&showGuidesToolbar=false&showHotspots=true&embedInDomain=&isEmbed=true'
          }
        ],
        categories: ['High Tech']
      },
      {
        id: 8,
        name: 'Identify Separation of Duties (SOD) Conflicts',
        pinLocation: {
          x: 87,
          y: 40
        },
        position: 'BOTTOM',
        height: '260',
        description:
          'Learn how Access Risk Management allows you to rapidly identify SOD and sensitive access risks across your SAP systems.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=93fe08a4-196c-404e-b032-45767d68dc03&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['High Tech'],
        drillDown: {
          animation: AnimationFinance
        }
      },
      {
        id: 9,
        name: 'GenAI Descriptions for Entitlements',
        pinLocation: {
          x: 75,
          y: 21
        },
        position: 'BOTTOM',
        height: '210',
        description:
          'Learn how GenAI descriptions for entitlements delivers accurate and clear descriptions to provide you with better information on which to make faster decisions.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=49520a4f-f714-49eb-9c63-c462a699f8c1&sh[…]e&showGuidesToolbar=false&showHotspots=true&source=app'
          }
        ],
        categories: ['High Tech']
      },
      {
        id: 10,
        name: 'Streamline role creation',
        pinLocation: {
          x: 8,
          y: 32
        },
        position: 'BOTTOM',
        height: '200',
        description:
          'Building and maintaining access roles is key to maintaining a productive and secure workforce in a least privilege environment.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=3bb8b3f2-d210-4a61-bbb8-22e074964ace&showGuide=true&showGuidesToolbar=false&showHotspots=true&isEmbed=true'
          }
        ],
        categories: ['High Tech']
      },

      {
        id: 12,
        name: 'Managing third-party non-employee identities',
        pinLocation: {
          x: 57,
          y: 45
        },
        position: 'BOTTOM',
        height: '200',
        description: 'See how Non-Employee Risk Management helps you manage third-party non-employee identities.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=1bf1412b-9cba-4e3c-8e56-fb47db6bd57d&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['High Tech']
      },
      {
        id: 13,
        name: 'Work Reassignment',
        pinLocation: {
          x: 78,
          y: 57
        },
        position: 'BOTTOM',
        height: '200',
        description: 'Learn how to reassign tasks automatically.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=46378b86-ddc4-4849-9bae-62e237b360f1&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['High Tech']
      },
      {
        id: 14,
        name: 'Integrate with Microsoft Teams',
        pinLocation: {
          x: 40,
          y: 4
        },
        position: 'BOTTOM',
        height: '200',
        description:
          'The SailPoint and Microsoft Teams integration empowers workers to easily make, approve, or deny access requests – all from within the Teams collaboration tool.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=59046adc-a4a7-4279-a889-eff9e60a3fe0&showGuide=true&showGuidesToolbar=false&showHotspots=true&source=app'
          }
        ],
        categories: ['High Tech']
      },
      {
        id: 15,
        name: 'Govern identity security with MySailPoint',
        description:
          'See how MySailPoint’s widget-based dashboard acts as the access governance command center to provide you insights across all your SailPoint solutions.',
        pinLocation: {
          x: 85,
          y: 73
        },
        position: 'BOTTOM',
        height: '200',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=32245c91-e75d-4930-931d-4b48f09d894c&sh[…]ue&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['High Tech']
      },
      {
        id: 16,
        name: 'Discover and manage access for unstructured data',
        description:
          'See how Data Access Security provides integrated insights on sensitive data to help organizations minimize the risks of unstructured data through intelligent automation.',
        pinLocation: {
          x: 61,
          y: 63
        },
        position: 'BOTTOM',
        height: '200',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=f73a10f7-7fd5-4ea3-8c63-4e30a403916d&sh[…]e&showGuidesToolbar=false&showHotspots=true&source=app'
          }
        ],
        categories: ['High Tech']
      }
    ]
  },
  government: {
    imageUrl: 'government.jpg',
    list: [
      {
        id: 1,
        name: 'Automate workflows for outliers',
        pinLocation: {
          x: 74,
          y: 12
        },
        position: 'TOP',
        height: '200',
        description:
          'Discover how to use Workflows to automate your identity security program and match the speed of modern business.',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=403dce53-cc87-4839-8e06-4ec7eab075c1&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Government']
      },
      {
        id: 2,
        name: 'Govern cloud workloads',
        description:
          'Increased cloud infrastructure adoption has underscored the need to closely govern and manage access across multiple cloud service providers, including Microsoft Azure, Amazon Web Services and Google Cloud Platform.',
        pinLocation: {
          x: 71,
          y: 22
        },
        position: 'BOTTOM',
        height: '200',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=64ee2ae6-148f-4178-b022-9d4546ab61c0&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Government']
      },
      {
        id: 3,
        name: 'Make smarter access decisions',
        description:
          'Access Recommendations help business managers and application owners make informed decisions, reduce certification fatigue, and improved productivity.',
        pinLocation: {
          x: 58,
          y: 5
        },
        position: 'BOTTOM',
        height: '200',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=6901be05-4bfb-4a21-ac6b-7d0d3f69c282&showGuide=true&showGuidesToolbar=false&showHotspots=true&source=app'
          }
        ],
        categories: ['Government']
      },
      {
        id: 4,
        name: 'New employee access request',
        description:
          'See how the IdentityNow Request Center streamlines the process of requesting access for yourself, your team, or anyone in your organization.',
        pinLocation: {
          x: 64,
          y: 72
        },
        position: 'BOTTOM',
        height: '200',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=ac6ceed1-0a5e-4119-981d-88e78fe7d077&showGuide=true&showGuidesToolbar=false&showHotspots=true&source=app'
          }
        ],
        categories: ['Government']
      },
      {
        id: 5,
        name: 'Identify Separation of Duties (SOD) Conflicts',
        description:
          'Learn how Access Risk Management allows you to rapidly identify SOD and sensitive access risks across your SAP systems.',
        pinLocation: {
          x: 23.5,
          y: 41
        },
        position: 'BOTTOM',
        height: '200',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=93fe08a4-196c-404e-b032-45767d68dc03&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Government']
      },
      {
        id: 6,
        name: 'Managing third-party non-employee identities',
        description: 'See how Non-Employee Risk Management helps you manage third-party non-employee identities.',
        pinLocation: {
          x: 53,
          y: 60
        },
        position: 'BOTTOM',
        height: '200',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=1bf1412b-9cba-4e3c-8e56-fb47db6bd57d&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Government']
      },
      {
        id: 7,
        name: 'Work Reassignment',
        description: 'Learn how to reassign tasks automatically.',
        pinLocation: {
          x: 37,
          y: 28
        },
        position: 'BOTTOM',
        height: '200',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=46378b86-ddc4-4849-9bae-62e237b360f1&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Government']
      },
      {
        id: 8,
        name: 'Govern identity security with MySailPoint',
        description:
          'See how MySailPoint’s widget-based dashboard acts as the access governance command center to provide you insights across all your SailPoint solutions.',
        pinLocation: {
          x: 81,
          y: 28
        },
        position: 'BOTTOM',
        height: '200',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=32245c91-e75d-4930-931d-4b48f09d894c&sh[…]ue&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Government']
      },
      {
        id: 9,
        name: 'Discover and manage access for unstructured data',
        description:
          'See how Data Access Security provides integrated insights on sensitive data to help organizations minimize the risks of unstructured data through intelligent automation.',
        pinLocation: {
          x: 77,
          y: 52
        },
        position: 'BOTTOM',
        height: '200',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=f73a10f7-7fd5-4ea3-8c63-4e30a403916d&sh[…]e&showGuidesToolbar=false&showHotspots=true&source=app'
          }
        ],
        categories: ['Government']
      },
      {
        id: 10,
        name: 'GenAI Descriptions for Entitlements',
        description:
          'Learn how GenAI descriptions for entitlements delivers accurate and clear descriptions to provide you with better information on which to make faster decisions.',
        pinLocation: {
          x: 57,
          y: 37
        },
        position: 'BOTTOM',
        height: '200',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=49520a4f-f714-49eb-9c63-c462a699f8c1&sh[…]e&showGuidesToolbar=false&showHotspots=true&source=app'
          }
        ],
        categories: ['Government']
      },
      {
        id: 11,
        name: 'Access Intelligence Center',
        description:
          'Discover key insights into your Identity Governance Administration program or create your own dashboards for greater visibility.',
        pinLocation: {
          x: 90,
          y: 20
        },
        position: 'BOTTOM',
        height: '200',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=f3b5127b-7aed-4d96-814b-fb0b636499a3&sh[…]ue&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Government']
      },
      {
        id: 12,
        name: 'AI-powered application onboarding',
        description: 'Easily apply core identity security functions to enterprise apps and accelerate time-to-value.',
        pinLocation: {
          x: 72,
          y: 38
        },
        position: 'BOTTOM',
        height: '200',
        demos: [
          {
            name: 'IFrame',
            iFrame:
              'https://demo-marketing.sailpoint.com/player/?demoId=c959b8f7-3182-4825-8c7c-c71a28e672d1&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
          }
        ],
        categories: ['Government']
      }
    ]
  }
};

export default data;
