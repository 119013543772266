import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListPopup } from '../../pages/listPopup';
import { SettingsPopup } from '../../pages/settingsPopup';
import { DemosPopup } from '../../pages/demosPopup';
import Svg from 'react-inlinesvg';
import Logo from '../../assets/SailPoint-Logo.svg';
import ClickPoint from '../../assets/ClickPoint.svg';
import ListView from '../../assets/ListView.svg';
import Settings from '../../assets/Settings.svg';
import { ArrowBack } from './styled';
import { useNavigationState } from '../../contexts/navigationState';

import {
  BackgroundImage,
  Button,
  ImageContainer,
  Listings,
  LogoContainer,
  RelativeWrapper,
  imageContainerStyle,
  ClickContainer,
  SettingsContainer,
  NameContainer,
  BackToTheCityContainer,
  DemoWrapper
} from '../../pages/styled';
import { initialSliderValue } from '../../constants';

export const Demo = ({ data }) => {
  const [listVisibility, setListVisibility] = useState(0);
  const [listPopupVisibility, setListPopupVisibility] = useState(0);
  const [settingsVisibility, setSettingsVisibility] = useState(false);
  const [listViewVisibility, setListViewVisibility] = useState(false);
  const [blurRatio, setBlurRatio] = useState(0);
  const [blurred, setBlurred] = useState(false);
  const [opened, setOpened] = useState(false);
  const [closing, setClosing] = useState(true);
  const [toggleValue, settoggleValue] = useState(() => {
    const saved = localStorage.getItem('toggle');
    return saved === 'true';
  });
  const [sliderValue, setSliderValue] = useState(() => {
    const saved = localStorage.getItem('slider');
    return parseInt(saved) || initialSliderValue;
  });
  const lastEvent = useRef(0);
  const frameRef = useRef(null);
  const contentRef = useRef(null);
  const timerRef = useRef(null);
  const navigate = useNavigate();
  const { demoId, setDemoId } = useNavigationState();

  useEffect(() => {
    const intervalId = window.setInterval(checkFocus, 100);

    // Clean up
    return () => {
      window.clearInterval(intervalId);
    };
  }, []);

  const checkFocus = () => {
    if (document.activeElement == frameRef.current) {
      onResetTimer();
      window.focus();
    }
  };

  useEffect(() => {
    resetControler();
  }, [toggleValue, sliderValue]);

  useEffect(() => {
    if (demoId) {
      onStartClick(demoId);
      setDemoId(null);
    }
  }, [demoId]);

  const resetControler = () => {
    // Clear the existing timer
    clearTimeout(timerRef.current);

    if (toggleValue) {
      if (lastEvent.current === sliderValue * 60) {
        navigate('/', { state: { onBack: false } });
      } else {
        timerRef.current = setTimeout(() => {
          lastEvent.current = lastEvent.current + 1;
          resetControler();
        }, 1000);
      }
    }
  };

  const onStartClick = (industryId) => {
    // Prevents the user from triggering multiple demos/popups at once
    if (listViewVisibility) {
      onListViewClose();
    }
    if (settingsVisibility || listPopupVisibility) {
      return;
    }
    onOpen();
    setListPopupVisibility(industryId);
  };

  const onOpen = () => {
    setBlurred(true);
    setBlurRatio(2);
    setTimeout(() => {
      setOpened(true);
    }, 750);
  };

  const onOpenSettings = () => {
    setClosing(true);
    setSettingsVisibility(true);
    onOpen();
  };

  const onOpenListView = () => {
    setClosing(true);
    setListViewVisibility(true);
    onOpen();
  };

  const onClose = () => {
    setListPopupVisibility(0);
    setBlurred(false);
    setBlurRatio(0);
    setOpened(false);
    setTimeout(() => {
      setListVisibility(0);
    }, 750);
  };

  const onSettingsClose = () => {
    setSettingsVisibility(false);
    setBlurred(false);
    setBlurRatio(0);
  };

  const onListViewClose = () => {
    setListViewVisibility(false);
    setBlurred(false);
    setBlurRatio(0);
  };

  const onGeneralClick = () => {
    if (opened) {
      onClose();
    }
    setClosing(false);
  };

  const onResetTimer = () => {
    lastEvent.current = 0;
  };

  const preventScroll = () => {
    contentRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const onConfigurationSave = (value) => {
    localStorage.setItem('toggle', value.toggleValue);
    settoggleValue(value.toggleValue);
    localStorage.setItem('slider', value.sliderValue);
    setSliderValue(value.sliderValue);
  };

  const handleBackClick = () => {
    // Prevents the user action when on popup
    if (settingsVisibility || listViewVisibility || listPopupVisibility) {
      return;
    }
    navigate('/', { state: { onBack: true } });
  };

  return (
    <>
      <ImageContainer ref={contentRef} style={imageContainerStyle} onScroll={preventScroll}>
        <RelativeWrapper onClick={onResetTimer}>
          <BackgroundImage
            src={`../../${data.imageUrl}`}
            blurred={blurred}
            blurRatio={blurRatio}
            onClick={onGeneralClick}
          />
          <LogoContainer blurred={blurred} blurRatio={blurRatio}>
            <Svg src={Logo} style={{ marginLeft: '-20px', marginTop: '-20px', width: '287px' }} />
          </LogoContainer>
          <BackToTheCityContainer blurred={blurred} blurRatio={blurRatio} onClick={handleBackClick}>
            <ArrowBack src={'/Arrow.svg'} />
            Back to the city
          </BackToTheCityContainer>

          <SettingsContainer
            blurred={blurred}
            blurRatio={blurRatio}
            onClick={onOpenSettings}
            pointerVisibility={!settingsVisibility && !listViewVisibility && listPopupVisibility === 0}
          >
            <Svg src={Settings} style={{ width: '30px' }} />
          </SettingsContainer>
          <Listings
            blurred={blurred}
            blurRatio={blurRatio}
            onClick={onOpenListView}
            pointerVisibility={!settingsVisibility && !listViewVisibility && listPopupVisibility === 0}
          >
            <Svg src={ListView} style={{ width: '30px' }} />
          </Listings>
          {data &&
            data.list.map((industry) => {
              return (
                <DemoWrapper key={industry.id} fullHeight={listPopupVisibility === industry.id}>
                  <ClickContainer
                    top={industry.pinLocation.y}
                    left={industry.pinLocation.x}
                    blurred={industry.id !== listVisibility ? blurred : !blurred}
                    blurRatio={blurRatio}
                    position={industry.position && industry.position.toUpperCase()}
                  >
                    <Button
                      onClick={() => {
                        onStartClick(industry.id);
                      }}
                      pointerVisibility={!settingsVisibility && !listViewVisibility}
                    >
                      <Svg src={ClickPoint} />
                    </Button>
                  </ClickContainer>
                  <Button
                    onClick={() => {
                      onStartClick(industry.id);
                    }}
                    pointerVisibility={!settingsVisibility && !listViewVisibility}
                  >
                    <NameContainer
                      top={industry.pinLocation.y}
                      left={industry.pinLocation.x}
                      blurRatio={blurRatio}
                      blurred={industry.id !== listVisibility ? blurred : !blurred}
                      nameLabelPosition={industry.position}
                    >
                      {industry.name}
                    </NameContainer>
                  </Button>
                  <ListPopup
                    industry={industry}
                    idVisible={listPopupVisibility}
                    onCloseModal={onClose}
                    frameRef={frameRef}
                  ></ListPopup>
                </DemoWrapper>
              );
            })}
        </RelativeWrapper>
      </ImageContainer>

      {settingsVisibility && (
        <SettingsPopup
          visible={closing}
          toggle={toggleValue}
          slider={sliderValue}
          onClose={onSettingsClose}
          onSave={onConfigurationSave}
          onClick={onResetTimer}
        />
      )}
      {listViewVisibility && (
        <DemosPopup
          visible={closing}
          onClose={onListViewClose}
          onClick={onResetTimer}
          industryList={data.list}
          onStartClick={onStartClick}
        />
      )}
    </>
  );
};

export default Demo;
