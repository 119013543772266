import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../data';
import {
  BackgroundImageZoom,
  Button,
  ClickContainerCity,
  ImageContainer,
  Listings,
  NameContainer,
  RelativeWrapper,
  imageContainerStyle,
  LogoContainer,
  SettingsContainer
} from '../styled';
import { WelcomePopup } from '../welcomePopup';
import { SettingsPopup } from '../settingsPopup';
import Svg from 'react-inlinesvg';
import ClickPoint from '../../assets/ClickPoint.svg';
import { useLocation } from 'react-router-dom';
import DemosPopup from '../demosPopup';
import data from '../../data';
import ListView from '../../assets/ListView.svg';
import Logo from '../../assets/SailPoint-Logo.svg';
import Settings from '../../assets/Settings.svg';
import { initialSliderValue } from '../../constants';
import { useNavigationState } from '../../contexts/navigationState';

export const City = () => {
  const location = useLocation();
  const contentRef = useRef(null);
  const backgroundImageRef = useRef(null);
  const [blurRatio, setBlurRatio] = useState(2);
  const [blurred, setBlurred] = useState(false);
  const onBack = location.state?.onBack || false;
  const [welcomeVisibility, setWelcomeVisibility] = useState(onBack ? false : true);
  const [coords, setCoords] = useState({ x: '50%', y: '50%' });
  const [zoomed, setZoomed] = useState(false);

  const [zoomOut, setZoomOut] = useState(false);
  const [demosPupUpVisibility, setDemosPupUpVisibility] = useState(false);
  const [fullDemoList, setFullDemoList] = useState([]);
  const [closing, setClosing] = useState(true);
  const [settingsVisibility, setSettingsVisibility] = useState(false);
  const { setDemoId } = useNavigationState();

  const navigate = useNavigate();
  const [sliderValue, setSliderValue] = useState(() => {
    const saved = localStorage.getItem('slider');
    return parseInt(saved) || initialSliderValue;
  });
  const [timerActive, setTimerActive] = useState(() => {
    const savedToggle = localStorage.getItem('toggle');
    return savedToggle === 'true';
  });
  const [remainingTime, setRemainingTime] = useState(sliderValue * 60); // Convert minutes to seconds

  useEffect(() => {
    if (onBack) {
      setZoomOut(true);
    }
  }, [onBack]);

  useEffect(() => {
    // Update the timer when the slider value changes
    setRemainingTime(sliderValue * 60);
  }, [sliderValue]);

  const resetTimer = () => {
    setRemainingTime(sliderValue * 60);
  };

  useEffect(() => {
    let timerId;

    if (timerActive && remainingTime > 0) {
      timerId = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime > 1) {
            return prevTime - 1;
          } else {
            setBlurred(true);
            setWelcomeVisibility(true);
            return 0;
          }
        });
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [timerActive, remainingTime]);

  useEffect(() => {
    //  Get the list of demos from the data file
    const financialDemos = data.finance.list;
    const healthcareDemos = data.healthcare.list;
    const highTechDemos = data.office.list;
    const governmentDemos = data.government.list;

    //  Merge the lists together
    const fullDemoList = financialDemos.concat(healthcareDemos, highTechDemos, governmentDemos);
    setFullDemoList(fullDemoList);
  }, []);

  const toggleDemosPopup = () => {
    setDemosPupUpVisibility(!demosPupUpVisibility);
  };

  const preventScroll = () => {
    contentRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const onWelcomeClose = () => {
    setWelcomeVisibility(false);
    setBlurred(false);
    setBlurRatio(0);
    resetTimer();
  };

  const handleBackgroundClick = (event, slug) => {
    const rect = backgroundImageRef.current.getBoundingClientRect();
    const x = ((event.clientX - rect.left) / rect.width) * 100;
    const y = ((event.clientY - rect.top) / rect.height) * 100;
    setCoords({ x: `${x}%`, y: `${y}%` });
    setZoomed(true);

    setTimeout(() => {
      window.location.href = `/${slug}`;
    }, 700);
  };

  const onOpen = () => {
    setBlurred(true);
    setBlurRatio(2);
  };

  const onOpenSettings = () => {
    setClosing(true);
    setSettingsVisibility(true);
    onOpen();
  };

  const onSettingsClose = () => {
    setSettingsVisibility(false);
    setBlurred(false);
    setBlurRatio(0);
  };

  const onConfigurationSave = (value) => {
    localStorage.setItem('toggle', String(value.toggleValue));
    setTimerActive(value.toggleValue);

    localStorage.setItem('slider', value.sliderValue);
    setSliderValue(value.sliderValue);
    resetTimer();
  };

  const onOpenListView = () => {
    setClosing(true);
    toggleDemosPopup();
    onOpen();
  };

  const onListViewClose = () => {
    toggleDemosPopup();
    setBlurred(false);
    setBlurRatio(0);
  };

  const handleDemoListStartClick = (id, demo) => {
    // Get demo category
    const category = demo.categories[0];
    // Define redirect url
    let redirectUrl = '';
    // if Finance, redirect to /finance, if  Healthcare, redirect to /healthcare, if High Tech, redirect to /office
    if (category === 'Finance') {
      redirectUrl = '/finance';
    } else if (category === 'Healthcare') {
      redirectUrl = '/healthcare';
    } else if (category === 'High Tech') {
      redirectUrl = '/office';
    } else if (category === 'Government') {
      redirectUrl = '/government';
    }
    // Redirect to the demo page
    navigate(redirectUrl);
    setDemoId(id);
    return;
  };

  return (
    <>
      <ImageContainer ref={contentRef} style={imageContainerStyle} onScroll={preventScroll}>
        <RelativeWrapper>
          <BackgroundImageZoom
            src={`../../${config.city.imageUrl}`}
            blurred={blurred || welcomeVisibility}
            blurRatio={blurRatio}
            zoomed={zoomed}
            origin={coords}
            ref={backgroundImageRef}
            zoomOut={zoomOut}
          />
          {!zoomed &&
            config.city.clickPoints &&
            config.city.clickPoints.map((point) => {
              return (
                <div key={point.id}>
                  <ClickContainerCity
                    top={point.pinLocation.y}
                    left={point.pinLocation.x}
                    position={point.position && point.position.toUpperCase()}
                    blurred={blurred || welcomeVisibility}
                    blurRatio={blurRatio}
                  >
                    <Button onClick={(event) => handleBackgroundClick(event, point.slug)} pointerVisibility={true}>
                      <Svg src={ClickPoint} />
                    </Button>
                  </ClickContainerCity>
                  <Button onClick={(event) => handleBackgroundClick(event, point.slug)} pointerVisibility={true}>
                    <NameContainer
                      top={point.pinLocation.y}
                      left={point.pinLocation.x}
                      blurred={blurred || welcomeVisibility}
                      blurRatio={blurRatio}
                    >
                      {point.name}
                    </NameContainer>
                  </Button>
                </div>
              );
            })}

          <LogoContainer blurred={blurred} blurRatio={blurRatio}>
            <Svg src={Logo} style={{ marginLeft: '-20px', marginTop: '-20px', width: '287px' }} />
          </LogoContainer>

          <SettingsContainer blurred={blurred} blurRatio={blurRatio} onClick={onOpenSettings}>
            <Svg src={Settings} style={{ width: '30px' }} />
          </SettingsContainer>
          <Listings blurred={blurred} blurRatio={blurRatio} onClick={onOpenListView} pointerVisibility={true}>
            <Svg src={ListView} style={{ width: '30px' }} />
          </Listings>
          {welcomeVisibility && <WelcomePopup visible={welcomeVisibility} onClose={onWelcomeClose} />}
        </RelativeWrapper>
      </ImageContainer>

      {!welcomeVisibility && settingsVisibility && (
        <SettingsPopup
          visible={closing}
          toggle={timerActive}
          slider={sliderValue}
          onClose={onSettingsClose}
          onSave={onConfigurationSave}
          onClick={() => {}}
        />
      )}

      {!welcomeVisibility && demosPupUpVisibility && (
        <DemosPopup
          visible={closing}
          onClose={onListViewClose}
          industryList={fullDemoList}
          onStartClick={handleDemoListStartClick}
        />
      )}
    </>
  );
};

export default City;
