import styled from 'styled-components';
import Svg from 'react-inlinesvg';

export const ArrowBack = styled(Svg)`
  transform: rotate(180deg);
  cursor: pointer;
  width: 16px;
  height: auto;

  path {
    fill: #fff;
  }
`;
