import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Tooltip = styled.div`
  width: 300px;
  background-color: #fff;
  border-radius: 16px;
  padding: 15px;
  -webkit-box-shadow: 0 0 36.8px 3.2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 36.8px 3.2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 36.8px 3.2px rgba(0, 0, 0, 0.2);
  ${(props) =>
    props.size &&
    `
      width: ${props.size}px;
    `}
`;

const TooltipTitle = styled.p`
  font-family: Poppins;
  font-size: 18px;
  color: #0070ca;
  margin: 5px auto;
  font-weight: 600;
  opacity: 0;
  height: 0;
  pointer-events: none;
`;

const TooltipDescription = styled.p`
  font-family: Poppins;
  font-size: 14px;
  color: #000;
  margin: auto;
`;

export const tooltip = ({ size, title, descriptions }) => {
  return (
    <>
      <Tooltip size={size}>
        {title && <TooltipTitle>{title}</TooltipTitle>}
        {descriptions.map((description) => (
          <TooltipDescription key={description}>{description}</TooltipDescription>
        ))}
      </Tooltip>
    </>
  );
};

tooltip.propTypes = {
  size: PropTypes.string.isRequired,
  title: PropTypes.string,
  descriptions: PropTypes.any.isRequired
};

export default tooltip;
