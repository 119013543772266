/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  ButtonText,
  DrillDownCircle,
  DrillDownColumnA,
  DrillDownColumnB,
  DrillDownMain,
  StartButton,
  WelcomeDescription,
  WelcomeLogo,
  WelcomeTitle,
  WelcomeTooltip,
  WrapDrillDown,
  DrillDownHeader,
  DrillDownClose,
  DrillDownVideo
} from './styled';
import Svg from 'react-inlinesvg';
import Logo from '../assets/SailPoint-Logo.svg';
import CloseIcon from '../assets/CloseIcon.svg';

export const DrillDown = ({ onClose, onButtonClick, demo }) => {
  const [opened, setOpened] = useState(false);
  const [closing, setClosing] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setOpened(true);
    }, 1500);
  }, []);

  return (
    <WrapDrillDown opened={opened} closing={closing}>
      <DrillDownMain>
        <DrillDownColumnA>
          <DrillDownHeader>
            <WelcomeLogo>
              <Svg src={Logo} />
            </WelcomeLogo>
            <DrillDownClose
              style={{ margin: '20px', width: '17px', float: 'right', cursor: 'pointer' }}
              src={CloseIcon}
              onClick={onClose}
            />
          </DrillDownHeader>
          <WelcomeTooltip>INTERACTIVE DEMOS</WelcomeTooltip>
          <WelcomeTitle>{demo.name}</WelcomeTitle>
          <WelcomeDescription>{demo.description}</WelcomeDescription>
          <StartButton onClick={onButtonClick} centered={false}>
            <ButtonText>Start Demo</ButtonText>
          </StartButton>
        </DrillDownColumnA>
        <DrillDownColumnB>
          <DrillDownCircle>
            {demo.drillDown.animation && <DrillDownVideo muted autoPlay loop src={demo.drillDown.animation} />}
          </DrillDownCircle>
        </DrillDownColumnB>
      </DrillDownMain>
    </WrapDrillDown>
  );
};

export default DrillDown;
