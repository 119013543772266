import React, { createContext, useContext, useState } from 'react';

const NavigationStateContext = createContext({});

const NavigationStateProvider = ({ children }) => {
  const [demoId, setDemoId] = useState(null);
  return <NavigationStateContext.Provider value={{ demoId, setDemoId }}>{children}</NavigationStateContext.Provider>;
};

export const useNavigationState = () => useContext(NavigationStateContext);

export default NavigationStateProvider;
