import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Svg from 'react-inlinesvg';
import Logo from '../assets/SailPoint-Logo.svg';
import {
  ButtonText,
  CloseButton,
  CloseButtonIcon,
  PreDemoPopupContainer,
  RelativeWrapperWelcomePopUp,
  StartButton,
  WelcomeDescription,
  WelcomeLogo,
  WelcomeTitle,
  WelcomeTooltip
} from './styled';
import CloseIcon from '../assets/CloseIcon.svg';

export const PreDemoPopup = ({ visible, onDemo, title, description, onStart, onClose }) => {
  const [opened, setOpened] = useState(false);
  const [closing, setClosing] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setOpened(true);
    }, 1500);
  }, []);

  useEffect(() => {
    if (!visible) {
      onClick();
    }
  }, [visible]);

  const onClick = () => {
    setClosing(false);
    setOpened(false);
    onStart();
  };

  return (
    <>
      <PreDemoPopupContainer opened={opened} closing={closing}>
        <RelativeWrapperWelcomePopUp>
          <CloseButton>
            <CloseButtonIcon src={CloseIcon} onClick={onClose} />
          </CloseButton>

          <WelcomeLogo>
            <Svg src={Logo} />
          </WelcomeLogo>
          <WelcomeTooltip>HANDS ON WITH SAILPOINT</WelcomeTooltip>
          <WelcomeTitle>{title} </WelcomeTitle>
          <WelcomeDescription>{description}</WelcomeDescription>
          <StartButton onClick={onClick} centered={!onDemo}>
            <ButtonText>Start demo</ButtonText>
          </StartButton>
        </RelativeWrapperWelcomePopUp>
      </PreDemoPopupContainer>
    </>
  );
};

PreDemoPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PreDemoPopup;

PreDemoPopup.defaultProps = {
  onDemo: false
};
