import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import Svg from 'react-inlinesvg';
import CloseIcon from '../assets/CloseIcon.svg';

const demosAnimation = keyframes`
  0% { height: 10px; width: 10px; left: 49%; top: 49%; }
  10% { height: 10px; width: 10px; left: 49%; top: 49%; }
  50% { height: 10px; width: 90%; left: 5%; top: 49%; }
  100% { height: 90%; width: 90%; top: 5%; }
`;

const reverseDemosAnimation = keyframes`
  0% { height: 90%; width: 90%; top: 5%;}
  50% { height: 10px; width: 90%; left: 5%; top: 49%; }
  90% { height: 10px; width: 10px; left: 49%; top: 49%; }
  100% { height: 10px; width: 10px; left: 49%; top: 49%; }
`;

const DemosContainer = styled.div`
  z-index: 1;
  width: 90%;
  height: 90%;
  content-visibility: ${(props) => (props.opened ? 'auto' : 'hidden')};
  background-color: #ffffff;
  position: absolute;
  top: 5%;
  left: 5%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  animation-name: ${(props) => (props.closing ? demosAnimation : reverseDemosAnimation)};
  animation-duration: 1.5s;
  padding: 20px;
  padding-bottom: 4px;
`;

const DemosTooltip = styled.span`
  width: 164px;
  height: 12px;
  margin-bottom: 14px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.14;
  letter-spacing: 1.4px;
  text-align: left;
  color: #415364;
  white-space: nowrap;
`;

const DemosTitle = styled.span`
  width: 500px;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  color: #0033a1;
`;

const DemosList = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 40px;
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: #edecea;
    outline: 1px solid transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #757575;
  }
`;

const StartButton = styled.button`
  width: 250px;
  height: 40px;
  border-radius: 4px;
  border: solid 0 #0071ce;
  background-color: #0071ce;
  cursor: pointer;
  &:hover {
    background-color: #005daa;
  }
`;

const ButtonText = styled.span`
  width: 127px;
  height: 19px;
  font-family: Poppins;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  font-weight: 600;
`;

const CloseButton = styled.div`
  width: 17px;
  position: absolute;
  cursor: pointer;
  top: 40px;
  right: 40px;
`;

const CloseButtonIcon = styled(Svg)`
  path {
    fill: #415364;
  }
`;

const DemoItem = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 30% 15%;
  grid-column-gap: 4.9%;
  height: 110px;
  width: 100%;
  align-items: center;
`;

const DemoCategory = styled.span`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  color: #0033a1;
  display: block;
`;

const DemoName = styled.span`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  color: #415364;
`;

const DemoDescription = styled.span`
  font-family: Poppins;
  font-size: 14px;
  line-height: 1.71;
  text-align: left;
  color: #415364;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #edebe9;
  margin-bottom: 16px;
  margin-top: 24px;
`;

export const DemosPopup = ({ visible, onClose, industryList, onStartClick }) => {
  const [opened, setOpened] = useState(false);
  const [closing, setClosing] = useState(true);
  const currentList = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setOpened(true);
    }, 1500);
  }, []);

  useEffect(() => {
    if (!visible) {
      onClick();
    }
  }, [visible]);

  const onCloseClick = () => {
    setClosing(false);
    setOpened(false);
    setTimeout(onClose(), 1500);
  };

  const onClick = () => {
    setClosing(false);
    setOpened(false);
    setTimeout(onClose(), 1500);
  };

  const generateDemosByCategory = () => {
    const demosByCategory = [];
    industryList.forEach((demo) => {
      demo.categories.forEach((category) => {
        demosByCategory.push({
          ...demo,
          displayCategory: category
        });
      });
    });

    // Sort by displayCategory so items of the same category are grouped together
    demosByCategory.sort((a, b) => {
      if (a.displayCategory < b.displayCategory) return -1;
      if (a.displayCategory > b.displayCategory) return 1;
      return 0;
    });

    return demosByCategory;
  };

  const demoList = generateDemosByCategory(industryList);
  let prevCategory = null;

  return (
    <DemosContainer opened={opened} closing={closing}>
      <CloseButton>
        <CloseButtonIcon src={CloseIcon} onClick={onCloseClick} />
      </CloseButton>
      <DemosTooltip>INTERACTIVE DEMOS</DemosTooltip>
      <DemosTitle>All Demos</DemosTitle>
      <Separator />

      <DemosList ref={currentList}>
        {demoList.map((demo, index) => {
          const shouldDisplayCategory = demo.displayCategory !== prevCategory;
          prevCategory = demo.displayCategory;
          return (
            <React.Fragment key={`${demo.displayCategory}_${demo.id}`}>
              {shouldDisplayCategory && index !== 0 && <Separator />}
              <DemoItem>
                <DemoCategory style={{ opacity: shouldDisplayCategory ? 1 : 0 }}>{demo.displayCategory}</DemoCategory>
                <DemoName>{demo.name}</DemoName>
                <DemoDescription>{demo.description}</DemoDescription>
                <StartButton onClick={() => onStartClick(demo.id, demo)}>
                  <ButtonText>Start demo</ButtonText>
                </StartButton>
              </DemoItem>
            </React.Fragment>
          );
        })}
      </DemosList>
    </DemosContainer>
  );
};

DemosPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  industryList: PropTypes.array.isRequired,
  onStartClick: PropTypes.func.isRequired
};

export default DemosPopup;
