import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import Svg from 'react-inlinesvg';
import CloseIcon from '../assets/CloseIcon.svg';
import { Toggle, Slider } from '@fluentui/react';

const SettingsAnimation = keyframes`
  0% { height: 10px; width: 10px; left: 49%; top: 49%; }
  10% { height: 10px; width: 10px; left: 49%; top: 49%; }
  50% { height: 10px; width: 36%; left: 32%; top: 49%; }
  100% { height: 40%; width: 36%; top: 30%; }
`;

const reverseSettingsAnimation = keyframes`
  0% { height: 40%; width: 36%; top: 30%;}
  50% { height: 10px; width: 36%; left: 32%; top: 49%; }
  90% { height: 10px; width: 10px; left: 49%; top: 49%; }
  100% { height: 10px; width: 10px; left: 49%; top: 49%; }
`;

const SettingsContainer = styled.div`
  width: 36%;
  height: 40%;
  content-visibility: ${(props) => (props.opened ? 'auto' : 'hidden')};
  background-color: #ffffff;
  position: absolute;
  top: 30%;
  left: 32%;
  border-radius: 16px;
  display: grid;
  animation-name: ${(props) => (props.closing ? SettingsAnimation : reverseSettingsAnimation)};
  animation-duration: 1.5s;
`;

const SettingsTitle = styled.span`
  width: 500px;
  margin: 20px 0 0 40px;
  font-family: Poppins;
  font-size: 50px;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  color: #0033a1;
`;

const ApplyButton = styled.button`
  width: 260px;
  height: 54px;
  margin: 20px auto;

  border-radius: 4px;
  border: solid 0 #0033a1;
  background-color: #0033a1;
  cursor: pointer;
  &:hover {
    background-color: #0033a1;
  }
`;

const ButtonText = styled.span`
  width: 127px;
  height: 19px;
  font-family: Poppins;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  font-weight: 600;
`;

const CloseButton = styled.div`
  width: 17px;
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
`;

const StyledBar = styled(Slider)`
  margin: 0 40px 0 40px;
`;

const OptionBox = styled.div`
  margin: 0 40px 0 40px;
`;

const MinuteIndicator = styled.label`
  font-family: Poppins;
  position: absolute;
  right: 55%;
  font-size: 18px;
  font-weight: 600;
  line-height: 2;
  letter-spacing: 0.18px;
  color: #415364;
`;

const MinuteLabel = styled.label`
  font-family: Poppins;
  position: absolute;
  left: 46%;
  font-size: 18px;
  font-weight: 600;
  line-height: 2;
  letter-spacing: 0.18px;
  text-align: center;
  color: #415364;
`;

const ResetTag = styled.label`
  font-family: Poppins;
  position: absolute;
  left: 40px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.67;
  letter-spacing: 1.8px;
  text-align: left;
  color: #415364;
`;

const ToggleIndicator = styled(Toggle)`
  position: absolute;
  right: 40px;

  &.is-checked .ms-Toggle-background {
    background-color: #0033a1;
  }
`;

const customSliderStyles = {
  activeSection: {
    background: '#0033a1 !important',
    selectors: {
      ':hover': {
        background: '#0033a1 !important'
      }
    }
  },
  inactiveSection: {
    background: '#f2f5f7 !important',
    selectors: {
      ':hover': {
        background: '#f2f5f7 !important'
      }
    }
  },
  line: {
    background: '#f2f5f7 !important'
  },
  thumb: {
    background: '#fff !important',
    border: 'none  !important',
    boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.25)  !important',
    selectors: {
      ':hover': {
        background: '#fff  !important'
      }
    }
  }
};

const customToggleStyles = {
  root: {
    '.ms-Toggle-innerContainer': {
      display: 'flex',
      flexDirection: 'row-reverse !important',
      alignItems: 'center !important'
    }
  },
  pill: {
    width: '60px',
    height: '30px',
    background: '#f2f5f7',
    borderRadius: '30px',
    border: 'none',
    selectors: {}
  },
  thumb: {
    width: '26px',
    height: '26px',
    background: '#fff !important',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
    selectors: {
      ':hover': {
        background: '#fff  !important'
      }
    }
  }
};

export const SettingsPopup = ({ visible, toggle, slider, onClose, onSave }) => {
  const [opened, setOpened] = useState(false);
  const [closing, setClosing] = useState(true);
  const [toggleValue, settoggleValue] = useState(toggle);
  const [sliderValue, setSliderValue] = useState(slider);
  const onToggleChange = (event, value) => settoggleValue(value);
  const onSliderChange = (value) => setSliderValue(value);

  useEffect(() => {
    setTimeout(() => {
      setOpened(true);
    }, 1500);
  }, []);

  useEffect(() => {
    if (!visible) {
      onCloseClick();
    }
  }, [visible]);

  const onCloseClick = () => {
    setClosing(false);
    setOpened(false);
    setTimeout(onClose, 1500);
  };

  const onSaveClick = () => {
    onSave({ toggleValue, sliderValue });
    onCloseClick();
  };

  return (
    <SettingsContainer opened={opened} closing={closing}>
      <CloseButton>
        <Svg src={CloseIcon} onClick={onCloseClick} />
      </CloseButton>
      <SettingsTitle>Settings</SettingsTitle>
      <OptionBox>
        <ResetTag>Reset Timer</ResetTag>

        <ToggleIndicator
          onText="ON"
          offText="OFF"
          onChange={onToggleChange}
          checked={toggleValue}
          styles={customToggleStyles}
        />
      </OptionBox>
      <OptionBox>
        <MinuteIndicator>{sliderValue}</MinuteIndicator>
        <MinuteLabel>Minutes</MinuteLabel>
      </OptionBox>
      <StyledBar
        styles={customSliderStyles}
        min={1}
        max={30}
        value={sliderValue}
        onChange={onSliderChange}
        showValue={false}
      />
      <ApplyButton onClick={onSaveClick}>
        <ButtonText>Apply</ButtonText>
      </ApplyButton>
    </SettingsContainer>
  );
};

SettingsPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggle: PropTypes.bool.isRequired,
  slider: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default SettingsPopup;
