import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DemoClickPoint from '../assets/DemoClickPoint.svg';
import Tooltip from './tooltip';
import Svg from 'react-inlinesvg';

const Button = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  opacity: 1;
  margin: auto;
  cursor: pointer;
`;

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: ${(props) => props.direction};
  top: ${(props) => props.top}%;
  left: ${(props) => props.left}%;
  transform: ${(props) => {
    switch (props.position) {
      case 'LEFT':
        return 'translate(-100%, -50%)';
      case 'RIGHT':
        return 'translate(0, -50%)';
      case 'TOP':
        return 'translate(-50%, -100%)';
      case 'BOTTOM':
        return 'translate(-50%, 0)';
      default:
        return 'none';
    }
  }};

  .ordered-item {
    margin-bottom: 16px;
  }
`;

export const ClickContainer = ({ clickPoint, onClickRight }) => {
  return (
    <Container
      top={clickPoint.y}
      left={clickPoint.x}
      direction={(() => {
        if (clickPoint.tooltip) {
          switch (clickPoint.tooltip.position.toUpperCase()) {
            case 'LEFT':
              return 'row';
            case 'RIGHT':
              return 'row-reverse';
            case 'TOP':
              return 'column';
            case 'BOTTOM':
              return 'column-reverse';
          }
        } else {
          return 'row';
        }
      })()}
      position={clickPoint.tooltip && clickPoint.tooltip.position.toUpperCase()}
    >
      {clickPoint.tooltip && (
        <Tooltip
          size={clickPoint.tooltip.size}
          title={clickPoint.tooltip.title}
          descriptions={clickPoint.tooltip.description}
        />
      )}
      <Button onClick={onClickRight}>
        <Svg src={DemoClickPoint} />
      </Button>
    </Container>
  );
};

ClickContainer.propTypes = {
  clickPoint: PropTypes.any.isRequired,
  onClickRight: PropTypes.func.isRequired
};

export default ClickContainer;
