import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Svg from 'react-inlinesvg';
import CloseIcon from '../assets/CloseIcon.svg';
import FullScreenIcon from '../assets/FullScreen.svg';
import ExitFullScreenIcon from '../assets/ExitFullScreen.svg';
import ClickContainer from './clickContainer';
import DrillDown from './DrillDown';
import PreDemoPopup from './preDemoPopup';

const PopupContainer = styled.div`
  z-index: 1;
  width: ${(props) => (props.fullScreen ? '100%' : '80%')};
  left: ${(props) => (props.fullScreen ? '0' : '10%')};
  top: ${(props) => (props.fullScreen ? '0' : 'unset')};
  background-color: #fff;
  border-color: white;
  position: absolute;
  -webkit-box-shadow: 0 0 36.8px 3.2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 36.8px 3.2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 36.8px 3.2px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 16px;
  padding: 50px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

const PopupHeader = styled.div`
  width: 100%;
`;

const DemoTitle = styled.span`
  color: #415364;
  margin: 0;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 600;
  letter-spacing: 1.4px;
`;

const IndustryName = styled.h3`
  color: #0033a1;
  margin: 0;
  font-size: 28px;
  margin-bottom: 17px;
`;

const DemoList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const IntroSlide = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
`;

const DemoRelativeWrapper = styled.div`
  position: ${(props) => (props.fullScreen ? 'fixed' : 'relative')};
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #fff;
`;

const DemoImage = styled.img`
  aspect-ratio: 16/9;
  object-fit: contain;
  width: 100%;
`;

const NavigateLeft = styled.div`
  background-image: url(../../Arrow.svg);
  transform: rotate(180deg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 16px;
  height: 50px;
  width: 50px;
  align-self: center;
  cursor: pointer;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  left: ${(props) => props.left}%;
`;

const NavigateRight = styled.div`
  background-image: url(../../Arrow.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 16px;
  height: 50px;
  width: 50px;
  align-self: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  cursor: pointer;
`;

const DemoIframe = styled.iframe`
  height: 100%;
  min-height: calc(100vh - 350px);
  width: 100%;
`;

const TextBubble = styled.div`
  -webkit-box-shadow: 0 0 36.8px 3.2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 36.8px 3.2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 36.8px 3.2px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 48px 32px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 32px;
  flex-direction: column;
  width: 50%;
`;

const ButtonDemo = styled.div`
  padding: 16px;
  min-width: 128px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #0271cc;
  color: white;
  font-family: Poppins;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DemoVideo = styled.video`
  width: 100%;
  @media (max-aspect-ratio: 16/9) {
    height: 100%;
  }
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
`;

const FullScreenTag = styled(Svg)`
  width: 40px;
  position: absolute;
  right: ${(props) => (props.fullScreen ? '50px' : '0px')};
  bottom: ${(props) => (props.fullScreen ? '50px' : '0px')};
  cursor: pointer;
`;

export const ListPopup = ({ industry, idVisible, onCloseModal, frameRef }) => {
  const videoRef = useRef(null);
  const popUpRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [step, setStep] = useState(0);
  const [showIntro, setShowIntro] = useState(step === 0);
  const [showConclusion, setShowConclusion] = useState(false);
  const [segment, setSegment] = useState(0);
  const [segmentClickPointVisible, setSegmentClickPointVisible] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);
  const [showDrillDown] = useState(industry.drillDown);
  const [showPreDemo, setShowPreDemo] = useState(true);

  useEffect(() => {
    if (idVisible === industry.id) {
      setVisible(true);
      setStep(0);
      setSegment(0);
      setSegmentClickPointVisible(true);
    }
  }, [idVisible]);

  const onClickLeft = () => {
    if (step === 0) {
      setStep(industry.demos.length - 1);
    } else if (showConclusion) {
      setShowConclusion(false);
    } else {
      setStep(step - 1);
    }
  };

  const onClickRight = () => {
    if (industry.demos[step] && industry.demos[step].video && industry.demos[step].segments.length > segment + 1) {
      setSegmentClickPointVisible(false);
      videoRef.current.play();
      setSegment(segment + 1);
    } else {
      setSegment(0);
      if (step === industry.demos.length - 1) {
        setShowConclusion(true);
      } else {
        setStep(step + 1);
      }
    }
  };

  const onStart = () => {
    setShowIntro(false);
    if (industry.demos[step] && industry.demos[step].video && industry.demos[step].segments.length > segment) {
      videoRef.current.play();
    }
  };

  const onClose = () => {
    setVisible(false);
    onCloseModal();
    setStep(0);
    setShowConclusion(false);
    setShowIntro(true);
    setShowPreDemo(true);
  };

  const onPlay = () => {
    setSegmentClickPointVisible(false);
    setTimeout(() => {
      videoRef.current.pause();
      setSegmentClickPointVisible(true);
    }, industry.demos[step].segments[segment].duration);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }, [videoRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [popUpRef]);

  const onGoFullScreen = () => {
    setFullScreen(true);
  };

  const onExitFullScreen = () => {
    setFullScreen(false);
  };

  const onDrillDownClick = () => {
    setShowPreDemo(false);
  };

  const onDrillDownClose = () => {
    setShowPreDemo(true);
    onClose();
  };

  return (
    <>
      {visible && (
        <>
          {showPreDemo ? (
            <>
              {showDrillDown ? (
                <DrillDown
                  onButtonClick={onDrillDownClick}
                  onClose={onDrillDownClose}
                  demo={industry}
                  visible={showDrillDown}
                />
              ) : (
                <PreDemoPopup
                  visible
                  onStart={() => setShowPreDemo(false)}
                  onClose={onClose}
                  title={industry.name}
                  description={industry.description}
                />
              )}
            </>
          ) : (
            <PopupContainer ref={popUpRef} fullScreen={fullScreen} transparentBg={showPreDemo && !showDrillDown}>
              <PopupHeader>
                <div>
                  <DemoTitle>INTERACTIVE DEMO</DemoTitle>
                  <Svg
                    style={{ margin: '20px', width: '17px', float: 'right', cursor: 'pointer' }}
                    src={CloseIcon}
                    onClick={onClose}
                  />
                  <IndustryName>{industry.name}</IndustryName>
                </div>
              </PopupHeader>
              <DemoList>
                {industry.demos[step] && industry.demos[step].iFrame && (
                  <DemoRelativeWrapper fullScreen={fullScreen}>
                    <DemoIframe ref={frameRef} src={industry.demos[step].iFrame} fullScreen={fullScreen}></DemoIframe>
                    {!fullScreen && (
                      <FullScreenTag src={FullScreenIcon} onClick={onGoFullScreen} fullScreen={fullScreen} />
                    )}
                    {fullScreen && (
                      <FullScreenTag src={ExitFullScreenIcon} onClick={onExitFullScreen} fullScreen={fullScreen} />
                    )}
                  </DemoRelativeWrapper>
                )}
                {((industry.demos[step] && industry.demos[step].imageUrl) ||
                  (industry.demos[step] && industry.demos[step].video)) && (
                  <>
                    <NavigateLeft
                      onClick={onClickLeft}
                      step={step}
                      visible={(!showIntro && step > 0) || showConclusion}
                    ></NavigateLeft>

                    <DemoRelativeWrapper fullScreen={fullScreen}>
                      <IntroSlide visible={showIntro}>
                        {industry && industry.intro && <TextBubble>{industry.intro}</TextBubble>}
                        <ButtonDemo onClick={onStart}>Start</ButtonDemo>
                      </IntroSlide>

                      <IntroSlide visible={showConclusion}>
                        {industry && industry.conclusion && <TextBubble>{industry.conclusion}</TextBubble>}
                        <ButtonDemo onClick={onClose}>Finish</ButtonDemo>
                      </IntroSlide>

                      {industry.demos[step] && industry.demos[step].imageUrl && (
                        <DemoImage src={`../../${industry.demos[step].imageUrl}`}></DemoImage>
                      )}

                      {industry.demos[step] && industry.demos[step].video && (
                        <>
                          <DemoVideo
                            autoplay
                            preload="true"
                            name="media"
                            ref={videoRef}
                            onPlay={onPlay}
                            loop
                            visible={!showIntro && !showConclusion}
                          >
                            <source
                              src={`../../${industry.demos[step] && industry.demos[step].video}`}
                              type={industry.demos[step].type}
                            ></source>
                          </DemoVideo>
                          {!showIntro &&
                            !showConclusion &&
                            industry.demos[step].segments[segment].clickPoint &&
                            segmentClickPointVisible && (
                              <ClickContainer
                                clickPoint={industry.demos[step].segments[segment].clickPoint}
                                step={step}
                                onClickRight={onClickRight}
                              />
                            )}
                        </>
                      )}

                      {!showIntro && !showConclusion && industry.demos[step].clickPoint && (
                        <ClickContainer
                          clickPoint={industry.demos[step].clickPoint}
                          step={step}
                          onClickRight={onClickRight}
                        />
                      )}
                      {!fullScreen && (
                        <FullScreenTag src={FullScreenIcon} onClick={onGoFullScreen} fullScreen={fullScreen} />
                      )}
                      {fullScreen && (
                        <FullScreenTag src={ExitFullScreenIcon} onClick={onExitFullScreen} fullScreen={fullScreen} />
                      )}
                    </DemoRelativeWrapper>
                    <NavigateRight visible={!showIntro && !showConclusion} onClick={onClickRight}></NavigateRight>
                  </>
                )}
              </DemoList>
            </PopupContainer>
          )}
        </>
      )}
    </>
  );
};

ListPopup.propTypes = {
  industry: PropTypes.any,
  idVisible: PropTypes.number,
  onCloseModal: PropTypes.func,
  frameRef: PropTypes.object
};

export default ListPopup;
